body{
    margin: 0;
    font-family: 'Roboto', sans-serif;
}
.no_decor a{
    text-decoration: none;
}
.block_center {
    justify-content: center;
}
.no_list_style{
    padding-left: 0;
}
.no_list_style li{
    list-style-type: none;
}
header{
    padding: 20px 0;
}
.header__logo{
    width: 100%;
}
.header__logo__full{
    width: 100%;
}
h3 {
    font-family: 'Roboto Slab', serif;
    font-size: 28px;
    font-weight: 700;
}
h2 {
    font-family: 'Roboto Slab', serif;
    font-size: 32px;
    font-weight: 500;
}
.select_none{
    user-select: none;
}
.header__menu{
    display: flex;
    position: relative;
    right: 13px;
}

.header__menu .header__menu__item{
    margin-right: 10px;
    display: flex;
}
.header__menu .header__menu__item a{
    padding: 12px 13px;
    border-radius: 17px;
    transition: all .2s;
}

.header__menu__item a{
    color: #000000;
    font-weight: 500;
    font-size: 17px;
}

.header__menu__item.active a,.header__menu__item a:hover {
    color: #FF5757;
    background: #FFFFFF;
}
footer{
    padding: 20px 0;
    font-size: 14px;
}

.contain_item a{
    width: 100%;
}

.contain_item .footer__copyright__logo img{
    width: 70%;
}

.test_block{
    min-height: 500px;
    background-color: #fff;
    padding-bottom: 40px;
}
.politic_block{
    display: flex;
    justify-content: center;
    color: #000000;
}
.politic_block a{
    color: #333333;
}


.contain_item a{
    color: #000000;
    font-size: 15px;
}
.footer__menu__link.active a,.contain_item a:hover,.politic_block a:hover{
    color: #F64A4A;
}
.contain_item li{
    margin-bottom: 20px;
}
.tac{
    text-align: center;
}
.footer__social-links svg{
    position: relative;
    margin-right: 10px;
    top: 5px;
}
.desktop-title{
    font-weight: bold;
    font-size: 28px;
}

.desktop-content{
    margin-bottom: 40px;
    font-size: 18px;
}
.main-content__product {
    cursor: pointer;
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 calc(100% / 3 - 12px);
    flex: 0 1 calc(100% / 3 - 12px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 20px 18px 18px;
    border: 2px solid #EEEEEE;
    border-radius: 12px;
    margin-bottom: 2rem;
    -webkit-transition: all .1s;
    -o-transition: all .1s;
    transition: all .1s;
    min-height: 340px;
    justify-content: space-between;
 
}

.main-content__product.product_pay{
    transition: .3s scale;
}
.main-content__product.product_pay:hover{
    scale: 1.1;
}


.main-content__product__name {
    font-size: 16px;
    line-height: 1.3;
    font-weight: 500;
    word-break: break-word;
}

.main-content__product__description {
    color: #848484;
    font-size: 13px;
    line-height: 1.3;
    word-break: break-word;
}
.main-content__product__cart-button {
    background-color: #0DB97A;
    
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 36px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50px;
    width: 100%;
    cursor: pointer;
    -webkit-transition: .2s background-color;
    -o-transition: .2s background-color;
    transition: .2s background-color;
}
.main-content__product__price {
    font-size: 18px;
    font-weight: 500;
    margin-right: initial;
    margin-bottom: 16px;
    display: block;
}
.main-content__product:hover {
    border: 2px solid #0DB97A;
}
.btn-bay {
    color: white;
    font-size: 18px;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
}

.main-content__product:hover .main-content__product__cart-button {
    background: #0A9361;
}

.main-content__product__image {
    width: 100%;
    height: 135px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.main-content__product__image img{
    max-width: 100%;
    max-height: 100%;
}

.splide__list{
    height: 400px;
}
.splide__list li{
    height: 100%;
    cursor: pointer;
}
.splide__list li a{
    max-height: 100%;
}

.splide__track{
    height: 400px;
}
.splide__slide img {
    height: 100%;
    width: 100%;
    border-radius: 10px;
}
.splide__arrow {
    background-color: #0DB97A !important;
    opacity: 1 !important;
}

.splide__arrow svg {
    fill: white !important;
    height: 1.2em;
    width: 1.2em;
}

.main-about__text p {
    font-size: 18px;
    line-height: 1.6;
}

.tittle-serv {
    font-weight: 500;
    font-size: 18px;
}
.contain-infor{
    border-top: 1px solid #eee;
    padding-top: 40px;
    margin: 40px 0;
}

.main-about__team-box__photo img{
    margin-right: 16px;
    min-height: 110px;
    min-width: 110px;
    height: 110px;
    width: 110px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
}
.main-about__team-box__person{
    display: flex;
    margin-bottom: 35px;
    border-radius: 12px;
    padding: 10px;
}

.main-about__team-box__person-position {
    color: #8A8D94;
    font-size: 16px;
    line-height: 1.2;
}
.main-about__team-box__person-name {
    font-weight: 700;
    font-size: 22px;
    line-height: 1.2;
    margin: 0;
}
.main-about__team-box__facebook-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100px;
    position: relative;
    background: #3B5998;
    color: #FFFFFF;
    padding: 3px 8px;
    border-radius: 4px;
    margin-bottom: 12px;
    margin-top: 8px;
    text-align: center;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    justify-content: space-around;
}
.main-about__team-box__photo{
    border-radius: 12px;
}
.main-about__team-box__facebook-link svg{
    margin-right: 10px;
    display: block;
}
.main-about__team-box__facebook-link:hover {
    color: #FFFFFF;
    text-decoration: none;
    background: #507DDA;
}

.contain-service img{
    height: 150px;
    margin-bottom: 25px;
}

.contain-help,.contain-service{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.contain-help img{
    height: 250px;
}


blockquote {
    border: none;
    margin: 0;
    background: transparent;
    font-weight: bold;
    text-align: right;
    color: black;
    padding: 30px 50px;
    font-family: 'Lato', sans-serif;
    font-size: 17.5px;
}
blockquote p {
    margin-top: 0;
    font-size: 22px;
    line-height: 1.25;
}
blockquote cite {
    font-style: normal;
    text-transform: uppercase;
    font-size: 14px;
}
.help-method p{
    font-size: 18px;
    line-height: 1.6;
}

.main-donate__variables__title {
    color: #000000;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    font-size: 20px;
}

.contain-link{
    border: 2px solid #EEEEEE;
    border-radius: 18px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.style-form{
    background-color: #F2FAFD;
    border-radius: 10px;
    padding: 25px 35px;
}

.style-form label,.style_upload_img{
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
}

.style-form input {
    padding: 10px;
    font-weight: 500;
    font-size: 16px;
    border-radius: 10px;
    border: 1px solid #BEC3C6;
}

.style-form textarea{
    max-width: 100%;
    resize: none;
    padding: 6px 12px;
    border-radius: 5px;
    border: 1px solid #BEC3C6;
}

.style-form span {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    font-size: 16px;
    color: #72767B;
    margin-bottom: 5px;
}

.but-green {
    background-color: #0DB97A;
    padding: 15px 30px;
    width: auto;
    border-radius: 50px;
    -webkit-transition: .3s background-color;
    -o-transition: .3s background-color;
    transition: .3s background-color;
    color: #FEFEFF;
    font-weight: 500;
    font-size: 20px;
    border: none;
    margin-top: 30px;
    cursor: pointer;
    transition: .2s background-color;
}
.but-green:disabled {
    background-color: #5cb896;
  }

.input-row{
    display: flex;
    width: 100%;
    justify-content: center;
}

label span.red,span span.red{
    color: red;
}

.style-form textarea:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
}

.main_cart__offert__action, .style-form .main_cart__offert__action__label{
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    font-size: 14px;
}
.style-form .main_cart__offert__action__label{
    margin-bottom: 0;
    width: 100%;
}
.modal-body .main_cart__offert__action__label a,.style-form .main_cart__offert__action__label a{
    margin-left: 8px;
    color: #0DB97A;
}
.modal-body .main_cart__offert__action__label a:hover,.style-form .main_cart__offert__action__label a:hover{
    color: #F64A4A;
}
.main_cart__offert__action{
    border: 2px solid #EEEEEE;
    border-radius: 8px;
    padding: 12px;
    text-align: left;
}
.style_logo_upload{
    cursor: pointer;
    padding: 25px;
    display: flex;
    justify-content: center;
    min-height: 150px;
    max-height: 600px;
    align-items: center;
    border: 2px dashed #BEC3C6;
    border-radius: 5px;
    max-width: 100%;
    overflow: hidden;
}
.style_logo_upload:hover{
    border: 2px dashed #0DB97A;
}
.style_logo_upload svg{
    font-size: 65px;
    fill: #0DB97A;
}
.style_logo_upload img{
    max-width: 100%;
    max-height: 100%;
    border-radius: 15px;
}

.cont_lib{
    display: flex;
    border: 2px solid #EEEEEE;
    border-radius: 18px;
    flex-direction: column;
    align-content: center;
    color: #000000;
    transition: all .1s;
    margin-bottom: 1rem;
}

.cont_lib:hover,.contain-link:hover{
    border: 2px solid #0DB97A;
    color: #0DB97A;
}
.contain-link:hover .main-donate__variables__title{
    color: #0DB97A;
}
.tittle_report{
    display: flex;
    width: 100%;
    justify-content: center;
}

.up_cntent h4{
    font-family: 'Roboto Slab', serif;
    font-size: 24px;
    font-weight: 700;
}

.up_cntent p ,.contain-infor p{
    font-size: 18px;
    line-height: 1.6;
}

.green{
    color: #0DB97A;
}

.tright{
    text-align: right;
}



.main_cart__offert__action {
    display: flex;
    align-items: center;
  }
  
  .modal-body .main-cart__offert__action__checkbox,.style-form .main-cart__offert__action__checkbox {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 2px solid #4caf50;
    border-radius: 4px;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    cursor: pointer;
    padding: 5px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .main-cart__offert__action__checkbox:checked {
    background-color: #4caf50;
    border: 2px solid #4caf50;
  }
  
  .main_cart__offert__action__label {
    cursor: pointer;
  }
  .main-cart__offert__action__checkbox::before{
    content: "✔";
    font-size: 12px;
    color: transparent;
  }
  .active .main-cart__offert__action__checkbox::before{
    content: "✔";
    font-size: 12px;
    color: #fff;
  }
  .contain-service,.contain-help{
    margin-bottom: 35px;
  }
  .main_about__team_box__person_contacts{
    display: flex;
    flex-direction: column;
  }

  .main_about__team_box__person_contacts a{
    margin-bottom: 10px;

    color: #000000;
  }
  .main_about__team_box__person_contacts .main-about__team-box__person-mail:hover,.main_about__team_box__person_contacts .main-about__team-box__person-mail:hover{
    color: #0A9361;
  }
  .main_about__team_box__person_contacts .main-about__team-box__facebook-link{
    color: #fff;
  }

  .contain-link img{
    max-width: 100%;
  }

  .main-content__product.paypal{
    background: #f0f8ff;
    border: 2px solid #d0edfd;
    position: relative;
    bottom: 20px;
  }
  .main-content__product.paynet{
    background: #fbf4f4;
    border: 2px solid #ffcccb;
    position: relative;
    top: 20px;
  }
  .main-content__product.pateon{
    background: #F6F3F3;
    border: 2px solid rgba(0,0,0,0.2);;
    position: relative;
    top: 20px;
  }

  .pateon .main-content__product__cart-button{
    background-color: #0f0f0f;
  }
  .pateon:hover .main-content__product__cart-button{
    background-color: #000;
  }
  .paypal .main-content__product__cart-button{
    background-color: #179bd7;
  }
  
  .paypal:hover .main-content__product__cart-button{
    background-color: #2b9ce7;
  }
  .paynet .main-content__product__cart-button{
    background-color: #EC6665;
  }
  .paynet:hover .main-content__product__cart-button{
    background-color: #ec5252;
  }
  .mb2r{
    margin-bottom: 2rem;
  }
  .one_pays{
    width: 100%;
  }
  .one_pays img{
    max-width: 100%;
    max-height: 100%;
  }
  .aic{
    align-items: center;
  }

  .main-about__team-box__persone {
    display: flex;
    margin-bottom: 35px;
    border: 2px solid #EEEEEE;
    border-radius: 12px;
    padding: 10px;
    cursor: pointer;
    align-items: center;
    color: #000000;
}

.contain_image_report{
    margin: 1rem 0;
    padding: 15px;
    cursor: pointer;
    border: 2px solid #EEEEEE;
    border-radius: 12px;
}
.contain_image_report img{
    max-width: 100%;
    border-radius: 8px;
}

.block_margin{
    margin: 1rem 0;
}

.margin_select{
    margin: 0.5rem 0;
}

.contain_lister{
    padding: 1rem ;
    border: 2px solid #EEEEEE;
    /* background-color: #F2FAFD; */
    margin-bottom: 1rem;
    border-radius: 8px;
    color: #000000;
}

.block_op a{
    display: flex;
    color: #000000;
    padding: 0.7rem ;
    align-items: center;
    line-height: 1.4;
    font-size: 17px;
    transition: .2s color;
}

.block_op a:hover{
    color: #0A9361;
}

.list_part{
    background-color: #F2FAFD;
    margin: 1rem 0;
    
    border-radius: 5px;
}

.ico_moduls{
    margin-right: 1rem;
    display: flex;
}

.ReactModal__Overlay{
    z-index: 999;
    display: flex;
    background-color: rgba(0, 0, 0, 0.5) !important;
}
.stype_upload_popup{
    background-color: #fff;
    display: flex;
    margin: auto;
    width: 580px;
    border-radius: 10px;
}

.modal-header{
    display: flex;
    align-items: flex-start;
    padding: 1.2rem;
    border-bottom: 1px solid #eceeef;
}
.modal-title {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.5;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: start;
}

.modal-header button{
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 35px;
}

.modal-body{
    padding: 1.2rem;
}

.modal-body p {
    font-size: 16px;
    line-height: 1.6;
}

.but-grey {
    background-color: grey;
    padding: 15px 30px;
    width: auto;
    border-radius: 50px;
    transition: .3s background-color;
    color: #FEFEFF;
    font-weight: 500;
    font-size: 20px;
    border: none;
    margin-top: 30px;
    cursor: pointer;
}

.active .but-grey,.but-grey.active {
    background-color: #0DB97A;
}

.active .but-grey:hover,.but-grey.active:hover {
    background-color: #05956B;
    color: #FEFEFF;
    text-decoration: none;
}
.modal-header .close{
    color: #000000;
    transition: .3s color;
}
.modal-header .close:hover{
    color: #F64A4A;
}

.modal-body .form_styles{
    display: flex;
    flex-direction: column;
}

.modal-body .form_styles>label{
    margin-bottom: 5px;
}

.modal-body .form_styles>input{
    margin-bottom: 15px;
    padding: 10px;
    font-weight: 500;
    font-size: 16px;
    border-radius: 10px;
    border: 1px solid #BEC3C6;
}
.one_donate{
    margin-top: 25px;
}
.mobile-menu-open {
    display: none;
}

.modal_dev {
    display: none;
}
.modal_dev.active{
    display: flex;
}




/* Добавляем стили для иконки мобильного меню */
.mobile-menu-icon {
    display: none; /* Скрываем иконку на больших экранах */
    cursor: pointer;
}

/* Стили для полосок иконки мобильного меню */
.mobile-menu-icon .bar {
    display: block;
    width: 30px;
    height: 3px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
}

/* Стили для активной иконки (крестика) */
.mobile-menu-icon.active .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.mobile-menu-icon.active .bar:nth-child(2) {
    opacity: 0;
}

.mobile-menu-icon.active .bar:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
}
/* Стили для мобильной иконки меню */
.mobile-menu-icon {
    display: none;
    cursor: pointer;
}

.mobile-menu-icon.active .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.mobile-menu-icon.active .bar:nth-child(2) {
    opacity: 0;
}

.mobile-menu-icon.active .bar:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
}

/* Стили для мобильного и десктопного меню */
.header__menu {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

}

.header__menu_mobile {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.4s, transform 0.4s;
}


.header__menu_mobile {
    display: none;
}

/* Стили для элементов меню */
.header__menu__item {
    margin: 0 10px;
}

/* .header__menu_mobile .header__menu__item,
.header__menu .header__menu__item {
    text-align: center;
    width: 100%;
    margin: 10px 0;
} */

/* Стили для активного элемента меню */
/* .header__menu__item.active a {
    color: #ff6600;
} */

/* Стили для модального окна на мобильных устройствах */
.modal_dev {
    display: none;
}

.modal_dev {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 10;
    opacity: 1;
    transform: translateY(-20);
    animation: fadeInDown 0.4s ease-in-out;
}

.modal_dev.active {
    display: block;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transform: translateY(0);
    animation: fadeInDown 0.4s ease-in-out;
}

/* Стили для десктопного меню */
.desktop {
    display: block;
}

/* Стили для анимации при открытии меню */
@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Медиа-запрос для отображения мобильной иконки меню */
@media screen and (max-width: 768px) {
    .header__menu {
        display: none;
    }

    .mobile-menu-icon {
        display: block;
    }

    .header__menu_mobile {
        display: flex;
        position: absolute;
        top: 80px;
        left: 0;
        right: 0;
        background-color: #fff;
        padding: 1rem 2rem;
        z-index: 10;
        opacity: 1;
        transform: translateY(0);
        animation: fadeInDown 0.4s ease-in-out;
    }

    .header__menu_mobile .header__menu__item a {
        width: 100%;
        display: flex;
        padding: 1rem 0;
    }

    .header__menu_mobile .header__menu__item {
        text-align: left;
        width: 100%;
        
    }
    .main-content__product.paypal {
        bottom: 0;
    }
    .main-content__product.pateon,.main-content__product.paynet{
        top: 0;
    }
    .stype_upload_popup{
        width: 90%;
    }
}
.flenx{
    display: flex;
    justify-content: flex-end;
}

.mobile-menu-icon,.header__logo{
    z-index: 999;
}

a.header__logo_mobile{
    width: 30%;
    display: flex;
}

.header__logo_mobile img{
    width: 100%;
}

.zi_pymary{
    z-index: 999;
}

.pof{
    position: fixed;
    background-color: #fff;
    z-index: 999;
}

.mb50{
    margin-bottom: 50px;
}




.verification-gallery {
    text-align: center;
}

.large-image-container {
    margin-bottom: 20px;
}

.large-image {
    max-width: 100%;
    height: auto;
    border: 2px solid #ccc;
    border-radius: 10px;
}

.thumbnail-container {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.thumbnail {
    width: 60px;
    height: 60px;
    object-fit: cover;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 5px;
    transition: border-color 0.3s;
}

.thumbnail.active,
.thumbnail:hover {
    border: 2px solid #0DB97A;
}
.colora{
    color: #0A9361;
}

